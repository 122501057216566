<template>
  <div class="editor">
    <div class="trigger-section">
      <NewActionBlock :index="-1" :class="{ 'has-blocks': blocks.length }" @selected="addBlock"/>
    </div>
    <div class="list-blocks">
      <ListBlock :blocks="blocks" level="1" :automation="automation" />
    </div>
  </div>
</template>

<script>
import NewActionBlock from './NewActionBlock'
import ListBlock from './ListBlock.vue'

export default {
  components: {
    NewActionBlock,
    ListBlock,
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      waitContacts: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    blocks() {
      let blocks = this.automation.blocks

      blocks = blocks.map(block => {
        if (!block.level) {
          block.level = '1'
        }

        return block
      })


      return blocks
    },
    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },


  methods: {
    addBlock(args) {
      let newBlock = JSON.parse(JSON.stringify(args.action))
      newBlock.isNewBlock = true

      if(args.action.action === 'CollectContactData') {
        this.customFields.forEach(function(item) {
          let format = item.type === 2 ? 'mm/dd' : ''
          newBlock.collectFields.push({name: item.id, label: item.name, type: item.type, format: format, enable: false, message: `Please reply with your ${ item.name}`});
        });
      }

      if (args.index === -1) {
        this.automation.blocks = [newBlock, ...this.automation.blocks]
      } else if (args.index < this.automation.blocks.length - 1) {
        this.automation.blocks = [...this.automation.blocks.slice(0, args.index + 1), newBlock, ...this.automation.blocks.slice(args.index + 1, this.automation.blocks.length)]
      } else {
        this.automation.blocks.push(newBlock)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
svg {
  fill: #5f667e;
  path {
    fill: none;
    stroke: #9298ad;
    stroke-width: 2;
  }
}
.plumbing {
  display: inline-block;
  width: 158px;
  height: 70px;
}

.if-branches {
  display: flex;
  align-items: center;
  justify-content: center;
  .if-yes-branch, .if-no-branch {
    width: 50%;
    justify-content: center;
    display: flex;
  }
  .if-yes {
    border: 2px solid #11866f;
    color: #11866f;
  }
  .if-no {
    border: 2px solid #e40e49;
    color: #e40e49;
  }
  .plumbing {
    position: absolute;
  }
}

.blocknode-path-label {
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
}
</style>
